<template>
    <modal @close="this.$emit('close')">
        <template v-slot:title>{{ form.categoryName }}</template>
        <template v-slot:head-bottom>
             <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body >
             <b-row>
                 <b-col
                 lg="12">
                     <b-card>
                         <b-card-title>
                             <h4><b>{{$t('Categories')}}</b></h4>
                         </b-card-title>
                         <b-card-text>
                            <input v-model="form.categoryName" class="form-control" />
                         </b-card-text>
                     </b-card>
                 </b-col>
                 <!-- <b-col
                  lg="6">
                     <b-card>
                         <b-card-title>
                             <h4><b>{{$t('created')}}</b></h4>
                         </b-card-title>
                         <b-card-text>
                             <h5> - {{form.create_user}}</h5>
                         </b-card-text>
                     </b-card>
                 </b-col> -->
             </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button 
                type="button" 
                v-if="this.perms['1403']"
                @click="dialogShow = true"
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>
            <button 
                type="button" 
                v-if="this.perms['1402']"
                @click="this.$emit('edit', this.form)"
                class="btn btn-info r waves-effect waves-light">
                {{ $t('Edit') }}
            </button>
        </template>
    </modal>

    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.$emit('remove', this.form)" />
 </template>
 
<script>
import modal from '@/components/modal/dialog'
import modalremove from '@/components/modal/modalremove'
import { storeS } from '@/store.js'


export default ({
    data(){
        return {
            form: {},
            dialogShow: false
        }
    },
    props: ["objcard"],
    components: { modal },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objcard
        },
    },
    mounted() {
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        }
    }
})
</script>

<style>
.badge {
    font-size: 12px;
}
</style>
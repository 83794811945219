<template>
    <PageHeader :title="title" />
    
    <tablecustom 
      @search="searchB" 
      :columns="columns" 
      :rows="rows" 
      :pages="objPages"
      :showpag="false"
      :createdform="this.perms['1401'] || this.perms['2000']" 
      :removeitem="this.perms['1403'] || this.perms['2000']"
      @create="create" 
      @open="open"
    />
    
    <viewcateg 
      v-if="showModal == true"  
      @close="closeModal" 
      :objcard="objcard" 
      @edit="edit"
      @remove="remove"
    ></viewcateg>
    
    <created 
      v-if="modalCreatedBox == true" 
      @close="closeModal" 
    />
  
</template>
  
<script>
import PageHeader from "@/components/page-header";
import { CategoriesProducts } from '@/API.js';
import tablecustom from '@/components/globaltable/index';
import created from './create'
import viewcateg from './view'
import { storeS } from "@/store";

let apiServe = new CategoriesProducts();
//   let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    created,
    viewcateg,
    tablecustom
  },
  data() {
    return {
      title: this.$t('ProductCategories'),
      search: '',
      modalCreatedBox: false,
      statuscreated: true,
      showModal: false,
      objPages: '',
      columns: [
        {
          name: this.$t('ID'),
          text: "categoryId",
          align: "left",
          status: true,
        },
        {
          name: this.$t("Point_name"),
          text: "categoryName",
          align: "left",
          status: true,
        },
      ],
      rows: [],
      objcard: {},
      pagelimit: '10'
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata(){
      apiServe.getAllCategories(this.search).then(result => {
        if(result.status == 'done') {
            this.rows = result.data
            this.objPages = result.data;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    create: function(){
      this.modalCreatedBox = true
    },
    open: function(e){
      apiServe.getCategory(e.categoryId).then(result => {
        if(result.status == 'done'){
          this.objcard = result.data;
          this.showModal = true;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    closeModal(){
      this.showModal = false;
      this.modalCreatedBox = false;
      this.getdata(this.objParams.page);
    },
    edit(e){
      apiServe.editCategoryProducts(e.categoryId, e).then(result => {
        if(result.status == 'done'){
          this.showModal = false;
          this.getdata();
          this.$toast.success(this.$t('alertChanged'));
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    remove: function(e){
      apiServe.deleteCategoryProducts(e.categoryId).then(result => {
        if(result.status == 'done'){
          this.showModal = false;
          this.getdata();
          this.$toast.success(this.$t('Removed'));
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    searchB(e){
      this.search = e
      this.getdata();
    },
  },
  computed: {
    filteredItems: function() {
        return this.rows
            // По полю поиска
            .filter(item => {
                return this.search == '' || item.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            })
    },
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    }
  },
  mounted() {
    this.eventBus.off('saveProductsCateg');
    this.eventBus.on('saveProductsCateg', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
</style>
